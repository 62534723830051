





















































































  import { GENDER, PRODUCTS } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { FormCompareKesehatanModel, ProductKesehatanStateModel } from '@/store/product/kesehatan/types';
  import { BSelectOption } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { Action, Mutation, State } from 'vuex-class';

  @Component({
    name: 'CompareKesehatan',
    components: {},
    mixins: [],
  })
  export default class CompareKesehatan extends Vue {
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) desciption!: string;
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!: string;

    @Action('GetKesehatan', { namespace: 'product/kesehatan' }) GetKesehatan!: () => Promise<ServerResponse>;
    @Action('CompareAction', { namespace: 'product/kesehatan' }) CompareAction!: (form: FormData) => Promise<ServerResponse>;

    @State('participant_type', { namespace: 'product/kesehatan' }) participant_type!: BSelectOption[];
    @State('form', { namespace: 'product/kesehatan' }) formKesehatan!: FormCompareKesehatanModel;
    @State((state: ProductKesehatanStateModel) => state.title?.HTMLtoString(), { namespace: 'product/kesehatan' }) _title!: string;
    @State((state: ProductKesehatanStateModel) => state.content?.HTMLtoString(), { namespace: 'product/kesehatan' }) content!: string;

    @Mutation('set-form', { namespace: 'product/kesehatan' }) SetForm!: (form: any) => void;

    private routeName = 'compare-' + PRODUCTS[4].name.toLowerCase().replace(' ', '-');

    public get form(): FormCompareKesehatanModel {
      return this.formKesehatan;
    }

    public set form(v: FormCompareKesehatanModel) {
      this.SetForm(v);
    }

    public get titleMutator(): string {
      return this.title ?? this._title;
    }

    public get descriptionMutator(): string {
      return this.desciption || this.content;
    }

    public GENDER = GENDER;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      this.GetKesehatan().then(()=>{
        let history = localStorage.getItem('kesehatan-history');
        if (typeof history === 'string') {
          this.form = JSON.parse(history);
          if (this.$route.name === this.routeName) this.OnSubmit({target: this.$refs['compareKesehatan']} as any);
        }
      });
    }
    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      if (Number(this.form.participant_status) === 1 && Number(this.form.child_status) === 1) this.form.participant_status = 3;
      if([1,3].indexOf(Number(this.form.participant_status))>=0) this.form.bod_individu = this.form.bod_husband

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if(this.form[key]) {
          if(this.form[key] instanceof Array) this.form[key].forEach((item, i) => {formData.append(`${key}[${i}]`, item)});
          else formData.append(key, this.form[key])
        }
      })
      localStorage.setItem('kesehatan-history', JSON.stringify(this.formKesehatan));
      this.CompareAction(formData).then((res) => {
        if (this.$route.name !== this.routeName) this.$router.push({ name: this.routeName });
      });
    }

    /**
     * OnChangeType
     */
    public OnChangeType(type: number) {
      if ([1, 3].indexOf(type) >= 0) {
        this.form.bod_child = [];
      } else {
        this.form.bod_child = null;
      }
    }

    /**
     * OnDeleteChild
     */
    public OnDeleteChild(childIndex) {
      this.form.bod_child.splice(childIndex, 1);
    }
  }
